import { action } from "../action-creator";
import { SINGLE_STYLIZATION } from "./single-stylization.reducer";
import {
    ISingleStylizationCreatePayload,
    ISingleStylizationUpdatePayload,
    IStylization,
} from "../../models/stylization.model";

export const types = {
    GET: "get",
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
    SET: "set",
    CLEAR: "clear",
    RESTORE_INITIAL: "restore initial",
};

export const getSingleStylizationAction = (stylizationId: number) =>
    action(SINGLE_STYLIZATION, types.GET, stylizationId);
export const createSingleStylizationAction = (payload: ISingleStylizationCreatePayload) =>
    action(SINGLE_STYLIZATION, types.CREATE, payload);
export const updateSingleStylizationAction = (payload: ISingleStylizationUpdatePayload) =>
    action(SINGLE_STYLIZATION, types.UPDATE, payload);
export const deleteSingleStylizationAction = (stylizationId: number) =>
    action(SINGLE_STYLIZATION, types.DELETE, stylizationId);
export const setSingleStylizationAction = (payload: IStylization) =>
    action(SINGLE_STYLIZATION, types.SET, payload);
export const clearSingleStylizationAction = () => action(SINGLE_STYLIZATION, types.CLEAR);
export const restoreSingleStylizationInitialStateAction = () =>
    action(SINGLE_STYLIZATION, types.RESTORE_INITIAL);
