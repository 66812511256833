const languages = require("./languages");
const alerts = require("./alerts");
const menuLinks = require("./menu-links");
const endpoints = require("./endpoints");
const schemas = require("./schemas");
const relations = require("./relations");
const fileTypes = require("./file-types");
const statusMap = require("./status-map");
const places = require("./places");
const translationKeys = require("./translation-keys");
const footerLinks = require("./footer-links");
const permissions = require(`./permissions`);
const accountLinks = require("./account-links");
const admLinks = require("./adm-links");

const API_URL = process.env.API_URL;

exports.config = {
    blogListingItemsCount: 12,
    baseUrl: API_URL,
    endpoints,
    rbac: permissions,
    alerts,
    fileTypes,
    footerLinks,
    headerTopLink: "https://www.hebe.pl/search?q=team%20x&prefn1=brand&prefv1=Ingrid",
    languages,
    loginLink: "http://37.130.30.31:1280/login",
    menuLinks,
    accountLinks,
    admLinks,
    places,
    relations,
    schemas,
    statusMap,
    translationKeys,
    messages: {
        form: {
            required: "To pole jest wymagane",
            email: "Niepoprawny format email",
            requiredConsent: "Zgoda jest wymagana",
        },
        password: {
            min: "Hasło musi składać się przynajmniej z 8 znaków",
            repeat: "Podane hasła nie są takie same",
        },
        networkError: "Wystąpił problem z siecią, sprawdź połączenie lub spróbuj później.",
        error: "Wystąpił błąd, spróbuj ponownie później",
        global: "Wystąpił błąd, spróbuj ponownie później...",
    },
    htmlCodes: {
        401: {
            survey: "Musisz posiadać konto aby kontynuować",
            seo: "401: Brak autoryzacji",
            message: "Wystąpił błąd podczas autoryzacji",
        },
        403: {
            survey: "[Zaloguj się](/auth) aby kontynuować.",
            seo: "403: Odmowa dostępu",
            message: "Brak uprawnień do wyświetlenia tej strony",
        },
        404: {
            seo: "404: Nie znaleziono strony",
            message: "Nie znaleziono takiej strony",
        },
        500: {
            seo: "500: Błąd serwera",
            message: "Wystąpił błąd podczas przetwarzania tego żądania",
        },
    },
    apiStatusMap: {
        idle: "idle",
        loading: "loading",
        success: "success",
        fail: "fail",
    },
    youtubeVideoLink: "https://www.youtube.com/watch?v=xtI_qjmOM9U",
};
