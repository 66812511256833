import { CookiesConfig, ModuleConfig } from "alterpage-react-cookies";

export const cookies: CookiesConfig = {
    pl: [
        {
            title: "Ciasteczko sesji: PHPSESSID",
            description: "Umożliwia prawidłowe funkcjonowanie Serwisu",
            group: "necessary",
        },
        {
            title:
                "Ciasteczka konfiguracyjne: cookie notice accepted, cookie analytics notice accepted",
            description: "Zapamiętują Twoją o decyzję dotyczącą o ustawień cookies",
            group: "necessary",
        },
        {
            title: "Google Analytics: _ga, _gat, _gid",
            description: "Umożliwiają śledzenie ruchów Użytkowników w Serwisie",
            group: "analytics",
        },
    ],
};

export const cookiesConfig: ModuleConfig = {
    cookies: {
        use: ["analytics"],
        names: {
            allow: "cookie notice accepted",
            analytics: "cookie analytics notice accepted",
        },
    },
    privacyPolicyLink: {
        pl: "/docs/polityka-prywatnosci.pdf",
    },
};
