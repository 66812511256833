import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { Thing } from "schema-dts";

import processStructuredData from "../utils/process-structured-data";
import { GraphWithoutContext } from "../utils/structured-data/create-graph";

export interface ISEOProps {
    description?: string;
    meta?: [];
    title?: string;
    image?: string;
    isArticle?: boolean;
    className?: string;
    navigation?: {
        prev?: string;
        next?: string;
    };
    structuredData?: Thing | GraphWithoutContext;
}

const SEO: React.FC<ISEOProps> = ({
    title,
    description,
    image,
    isArticle = false,
    children,
    navigation,
    structuredData,
    className = "",
}) => {
    const { site } = useStaticQuery(query);
    const { pathname } = useLocation();
    const { languages, language, pagePaths, defaultLanguage } = useI18next();

    const {
        title: defaultTitle,
        description: defaultDescription,
        image: defaultImage,
        url: siteUrl,
    } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image || `${siteUrl}${defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };

    const { prev, next } = navigation || {};

    const processedStructuredData = structuredData
        ? processStructuredData(structuredData)
        : undefined;

    return (
        <Helmet
            title={seo.title}
            {...(title
                ? {
                      titleTemplate: `%s | ${defaultTitle}`,
                  }
                : {})}
            htmlAttributes={{ lang: language }}
        >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content={isArticle ? "article" : "website"} />
            <meta
                property="og:title"
                content={`${seo.title} ${title ? `| ${defaultTitle}` : ""}`}
            />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />

            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />

            {languages
                .filter((lang) => language !== lang)
                .map((lang, index) => {
                    const path =
                        lang === defaultLanguage ? pagePaths[lang] : `/${lang}${pagePaths[lang]}`;
                    return (
                        <link
                            key={`href-link-${index}`}
                            rel="alternate"
                            href={`${siteUrl}${path}`}
                            hrefLang={lang}
                        />
                    );
                })}
            {prev && <link rel="prev" href={prev} />}
            {next && <link rel="next" href={next} />}
            {structuredData ? (
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        ...(typeof processedStructuredData === "object"
                            ? processedStructuredData
                            : {}),
                    })}
                </script>
            ) : null}
            <html className={className} />
            {children}
        </Helmet>
    );
};

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                image
                url
            }
        }
    }
`;

export default SEO;
