const endpoints = {
    register: "register",
    login: "login",
    logout: "logout",
    check: "my-account",
    profiles: "profiles", // profiles/{id}
    activate: "activate", // activate/{token}
    passwordChange: "password-change",
    passwordResetRequest: "password-reset-request",
    passwordReset: "password-reset",
    resendActivationEmail: "resend-activation-email",
    subscribeNewsletter: "newsletter/subscribe",
    unSubscribeNewsletter: "newsletter/unsubscribe",
};

export { endpoints };
