import React, { useEffect } from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import {
    container,
    section,
    content,
    text,
    titleSvg,
    header,
    list,
    item,
    card,
    error,
} from "./stylization-view.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import Congratulation from "../../../assets/images/svg/success.svg";
import { IStylization } from "../../../models/stylization.model";
import { TStatus } from "../../../models/status.model";
import { getAbsolutePath } from "../../utility/routes.utility";
import {
    selectSingleStylization,
    selectSingleStylizationStatus,
} from "../../../redux/single-stylization/single-stylization.selectors";
import {
    clearSingleStylizationAction,
    getSingleStylizationAction,
} from "../../../redux/single-stylization/single-stylization.actions";

import Section from "../../../components/molecules/section";
import Title from "../../../components/atoms/title";
import ProductCard from "../molecules/product-card";
import Button from "../../../components/atoms/button";
import Loader from "../../../components/atoms/loader";

type TStylizationViewProps = {
    stylizationId?: number;
    isCongratulationSite?: boolean;
} & RouteComponentProps;

const StylizationView: React.FC<TStylizationViewProps> = ({
    stylizationId,
    isCongratulationSite,
}) => {
    const { t } = useI18next();
    const dispatch = useDispatch();
    const stylization: IStylization | null = useSelector(selectSingleStylization);
    const status: TStatus = useSelector(selectSingleStylizationStatus);

    const { name, products = [], occasion } = stylization || {};

    const handleReturn = () => {
        navigate(getAbsolutePath("ACCOUNT_STYLIZATIONS"));
    };

    useEffect(() => {
        if (!stylizationId) return;
        dispatch(getSingleStylizationAction(stylizationId));
        return () => {
            dispatch(clearSingleStylizationAction());
        };
    }, [dispatch, stylizationId]);

    return (
        <div className={`${container} ${mainGrid}`}>
            <Section className={section}>
                <div className={content}>
                    {(!status || status === "loading" || status === "idle") && (
                        <Loader position={"absolute"} />
                    )}
                    {status === "fail" && (
                        <p className={error}>{t("generator.stylization.fail")}</p>
                    )}
                    {status === "success" && stylization && (
                        <>
                            <div className={header}>
                                {isCongratulationSite ? (
                                    <>
                                        <Congratulation className={titleSvg} />
                                        <h1 className={text}>{t("generator.stylization.ready")}</h1>
                                    </>
                                ) : (
                                    <Title
                                        Tag="h1"
                                        subtitle={occasion?.label}
                                        align="center"
                                        fontStyle="italic"
                                    >
                                        {name}
                                    </Title>
                                )}
                            </div>
                            <ul className={list}>
                                {products.map((product) => {
                                    return (
                                        <li key={product.productId} className={item}>
                                            <ProductCard product={product} className={card} />
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}
                </div>
                <Button onClick={handleReturn} type="filled">
                    {isCongratulationSite
                        ? t(`generator.stylization.ready.button`)
                        : t(`generator.stylization.view.button`)}
                </Button>
            </Section>
        </div>
    );
};

export default StylizationView;
