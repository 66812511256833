import { navigate } from "gatsby";

import actionName from "../action-creator";
import { SINGLE_STYLIZATION } from "./single-stylization.reducer";
import {
    restoreSingleStylizationInitialStateAction,
    setSingleStylizationAction,
    types,
} from "./single-stylization.actions";
import endpoints from "../../config/endpoints";
import { actions, sendApiRequest } from "../api/api.actions";
import { statusIdle } from "../ui/ui.actions";
import { setAlert } from "../alerts/alerts.actions";
import { getAbsolutePath } from "../../apps/utility/routes.utility";

const singleStylizationMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta } = action;

    next(action);

    let fetchConfig;

    switch (type) {
        case actionName(SINGLE_STYLIZATION, types.GET):
            fetchConfig = {
                url: endpoints.stylizations.single(payload),
                method: "GET",
                withCredentials: true,
                params: {
                    expand: "products",
                },
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;
        case actionName(SINGLE_STYLIZATION, `${types.GET} ${actions.API_SUCCESS}`):
            dispatch(setSingleStylizationAction(payload));
            break;

        case actionName(SINGLE_STYLIZATION, types.CREATE):
            fetchConfig = {
                url: endpoints.stylizations.create,
                method: "POST",
                withCredentials: true,
                data: payload,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;
        case actionName(SINGLE_STYLIZATION, `${types.CREATE} ${actions.API_SUCCESS}`):
            dispatch(setSingleStylizationAction(payload));
            navigate(getAbsolutePath('GENERATOR_STYLIZATION_CONGRATULATIONS'), { replace: true })
            break;
        case actionName(SINGLE_STYLIZATION, `${types.CREATE} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się zapisać stylizacji. Spróbój ponownie później.",
                    },
                    meta
                )
            );
            break;

        case actionName(SINGLE_STYLIZATION, types.UPDATE):
            fetchConfig = {
                url: endpoints.stylizations.update(payload.stylizationId),
                method: "PATCH",
                withCredentials: true,
                data: payload.data,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;
        case actionName(SINGLE_STYLIZATION, `${types.UPDATE} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się zapisać zmian. Spróbój ponownie później.",
                    },
                    meta
                )
            );
            break;

        case actionName(SINGLE_STYLIZATION, types.DELETE):
            fetchConfig = {
                url: endpoints.stylizations.delete(payload),
                method: "DELETE",
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;
        case actionName(SINGLE_STYLIZATION, `${types.DELETE} ${actions.API_SUCCESS}`):
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: "Stylizacja została usunięta.",
                    },
                    meta
                )
            );
            break;
        case actionName(SINGLE_STYLIZATION, `${types.DELETE} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się usunąć stylizacji. Spróbój ponownie później.",
                    },
                    meta
                )
            );
            break;

        case actionName(SINGLE_STYLIZATION, types.CLEAR):
            dispatch(statusIdle({ actionName: types.CLEAR, entity: SINGLE_STYLIZATION }));
            dispatch(restoreSingleStylizationInitialStateAction());
            break;

        default:
            break;
    }
};

export default singleStylizationMiddleware;
