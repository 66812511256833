import { graphql, useStaticQuery } from "gatsby";
import { ModuleConfig } from "alterpage-react-cookies";

import { IFiles } from "../../models/cookie-content-file.model";
import getNodes from "../../utils/get-nodes";

export default function useCookiesTranslations(defaultConfig: ModuleConfig) {
    const { allFile } = useStaticQuery<IFiles>(query);

    const cookiesContent = getNodes(allFile)
        .filter((file) => file.relativeDirectory === "pl")
        .reduce<ModuleConfig["content"]>(
            (acc, { relativeDirectory, name, childMarkdownRemark }) => {
                if (acc) {
                    if (!acc[relativeDirectory]) {
                        acc[relativeDirectory] = {};
                    }

                    acc[relativeDirectory] = {
                        ...acc[relativeDirectory],
                        [name]: childMarkdownRemark.rawMarkdownBody,
                    };
                }

                return acc;
            },
            {}
        );

    return {
        ...defaultConfig,
        content: cookiesContent,
    };
}

const query = graphql`
    query {
        allFile(filter: { sourceInstanceName: { eq: "cookies" }, extension: { eq: "md" } }) {
            edges {
                node {
                    name
                    relativeDirectory
                    childMarkdownRemark {
                        rawMarkdownBody
                    }
                }
            }
        }
    }
`;
