import { actions, receiveApiFail, receiveApiSuccess } from "./api.actions";
import instance from "../../api/axiosInstance";
import { clearAllAlerts } from "../alerts/alerts.actions";
import { fetchCheckUser } from "../user/user.actions";

const apiMiddleware = (state) => (next) => async (action) => {
    const { dispatch } = state;
    const { type, payload, meta = {} } = action;
    const { entity, actionName } = meta;

    let errorPayload;

    let clearAlerts = true;
    if (meta.clearAlerts !== undefined) {
        clearAlerts = meta.clearAlerts;
    }

    next(action);

    if (entity && actionName && type.includes(actions.API_REQUEST)) {
        if (clearAlerts) {
            dispatch(clearAllAlerts());
        }

        try {
            const response = await instance(payload);
            dispatch(receiveApiSuccess(response?.data, meta));
        } catch (error) {
            if (error.response) {
                errorPayload = error.response;
                if (
                    [401, 403].includes(errorPayload.status) &&
                    type !== "[USER] fetch_check_user [API] request"
                ) {
                    dispatch(fetchCheckUser());
                }

                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                errorPayload = error.request;
            } else if (error.message) {
                // Something happened in setting up the request that triggered an Error
                errorPayload = error.message;
            }
            console.log(error);

            dispatch(receiveApiFail(errorPayload, meta));
        }
    }
};

export default apiMiddleware;
