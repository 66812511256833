import actionName from "../action-creator";
import { types } from "./single-stylization.actions";

const SINGLE_STYLIZATION = `[SINGLE_STYLIZATION]`;

const initialState = null;

function singleStylizationReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actionName(SINGLE_STYLIZATION, types.SET):
            return payload;
        case actionName(SINGLE_STYLIZATION, types.RESTORE_INITIAL):
            return initialState;
        default:
            return state;
    }
}

export { initialState, SINGLE_STYLIZATION };
export default singleStylizationReducer;
