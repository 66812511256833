import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    selectUser,
    selectUserApiStatus,
    selectUserProfile,
} from "../../../redux/user/user.selectors";
import {
    checkUser,
    loginUser,
    logoutUser,
    registerUser,
    confirmUser,
    changePassword,
    resetPasswordRequest,
    resetPassword,
    resendActivationEmail,
    updateProfile,
    updateNewsletter as reduxUpdateNewsletter,
} from "../../../redux/user/user.actions";
import {
    IUserLogin,
    IUserPassChange,
    IUserPassReset,
    IUserPassResetRequest,
    IUserRegister,
    IUserSubscribePayload,
} from "../models/payloads.model";

function useUser() {
    const data = useSelector(selectUser);
    const profile = useSelector(selectUserProfile);
    const status = useSelector(selectUserApiStatus);
    const isLoggedIn = data && Object.keys(data).length > 0;
    const dispatch = useDispatch();

    return {
        data,
        profile,
        status,
        isLoggedIn,
        logIn: (payload: IUserLogin) => {
            dispatch(loginUser(payload));
        },
        logOut: () => {
            dispatch(logoutUser());
        },
        check: () => {
            dispatch(checkUser());
        },
        register: (payload: IUserRegister) => {
            dispatch(registerUser(payload));
        },
        confirm: useCallback(
            (payload: string) => {
                dispatch(confirmUser(payload));
            },
            [dispatch]
        ),
        passChange: (payload: IUserPassChange) => {
            dispatch(changePassword(payload));
        },
        passResetRequest: (payload: IUserPassResetRequest) => {
            dispatch(resetPasswordRequest(payload));
        },
        passReset: (payload: IUserPassReset) => {
            dispatch(resetPassword(payload));
        },
        updateNewsletter: (payload: IUserSubscribePayload) => {
            dispatch(reduxUpdateNewsletter(payload));
        },
        resendActivationEmail: () => dispatch(resendActivationEmail()),
        updateProfile: useCallback(
            (payload: { name?: string; email?: string; avatarBase64?: string }) => {
                const newPayload = {
                    ...payload,
                    firstName: payload.name,
                };
                dispatch(updateProfile(newPayload));
            },
            [dispatch]
        ),
    };
}

export default useUser;
