// extracted by mini-css-extract-plugin
export var card = "stylization-view-module--card--WVT2J";
export var container = "stylization-view-module--container--I8cnq";
export var content = "stylization-view-module--content--iW53g";
export var error = "stylization-view-module--error--O1S9A";
export var header = "stylization-view-module--header--xnn95";
export var item = "stylization-view-module--item--2TSHk";
export var list = "stylization-view-module--list--oSEft";
export var section = "stylization-view-module--section--9Hai8";
export var text = "stylization-view-module--text--DwRH2";
export var titleSvg = "stylization-view-module--title-svg--vyaHo";